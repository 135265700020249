import classnames from 'classnames'
import { includes } from 'lodash'
import dynamic from 'next/dynamic'
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { useInView } from 'react-cool-inview'

interface LazyLoadContextType {
  withoutLazyLoadKeys: string[]
  withoutLazyLoad: (key: string) => Promise<string>
  componentLoaded: (key: string) => void
}

const LazyLoadContext = createContext<LazyLoadContextType>({
  withoutLazyLoadKeys: [],
  withoutLazyLoad: async (key: string) => Promise.resolve(key),
  componentLoaded: () => undefined
})

export const LazyLoadProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const listeners = useRef({})

  const [withoutLazyLoadKeys, setWithLazyLoadKeys] = useState<string[]>([])

  const componentLoaded = key => {
    const listener = listeners.current[key]
    if (listener) {
      listener()
      listeners.current[key] = null
    }
  }

  const withoutLazyLoad = async (key: string) => {
    if (includes(withoutLazyLoadKeys, key)) {
      return Promise.resolve(key)
    }
    setWithLazyLoadKeys([...withoutLazyLoadKeys, key])
    return new Promise<string>(resolve => {
      listeners.current[key] = () => {
        resolve(key)
      }
    })
  }

  return (
    <LazyLoadContext.Provider
      value={{ withoutLazyLoadKeys, withoutLazyLoad, componentLoaded }}
    >
      {children}
    </LazyLoadContext.Provider>
  )
}

export const useLazyLoad = () => {
  const { withoutLazyLoadKeys, withoutLazyLoad, componentLoaded } =
    useContext(LazyLoadContext)

  return { withoutLazyLoadKeys, withoutLazyLoad, componentLoaded }
}

interface WithLazyLoadOptions {
  rootMargin?: string
  lazyLoadKey?: string
  wrapperProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  >
}

export const withLazyLoad = <P,>(
  Component: React.ComponentType<P>,
  {
    rootMargin = '50px',
    lazyLoadKey = '',
    wrapperProps: { className = '', ...sectionProps } = {}
  }: WithLazyLoadOptions = {}
) => {
  const DynamicComponent = dynamic(() => Promise.resolve(Component), {
    ssr: false
  })

  return (props: P) => {
    const { withoutLazyLoadKeys, componentLoaded } = useLazyLoad()
    const [withoutLazyLoad, setWithoutLazyLoad] = useState(false)

    const { observe, inView, unobserve } = useInView({
      unobserveOnEnter: true,
      rootMargin: rootMargin,
      threshold: 0.25
    })

    useEffect(() => {
      if (lazyLoadKey && (inView || withoutLazyLoad)) {
        componentLoaded(lazyLoadKey)
      } else if (lazyLoadKey && includes(withoutLazyLoadKeys, lazyLoadKey)) {
        setWithoutLazyLoad(true)
        unobserve()
      }
    }, [withoutLazyLoadKeys])

    useEffect(() => {
      if (withoutLazyLoad) {
        componentLoaded(lazyLoadKey)
      }
    }, [withoutLazyLoad])

    return (
      <section
        ref={observe}
        className={classnames('lazy-load-wrapper', className)}
        {...sectionProps}
      >
        {(inView || withoutLazyLoad) && (
          <DynamicComponent {...(props as any)} />
        )}
      </section>
    )
  }
}
