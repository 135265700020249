import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
interface ICartState {
  currentSkuIdForFinancing: string
  currentEasyPayOptIdForFinancing: string
  currentSecondaryOptIdForFinancing: string
  estimatedTotal: number
  isNewCheckoutFlow: boolean
  promoAlertMessage: {
    description?: string
    message?: string
    promoCode?: string
  }
  cartInitData?: {
    storeConfig: {
      audioEyeEnabled: boolean
      showStoreDistance: boolean
      rewardsCertificateLoginNecessary: boolean
      checkoutSynchronyRewardsEnabled: boolean
    }
  }
}

const initialState: ICartState = {
  currentSkuIdForFinancing: '',
  currentEasyPayOptIdForFinancing: '',
  currentSecondaryOptIdForFinancing: '',
  estimatedTotal: 0,
  promoAlertMessage: {},
  isNewCheckoutFlow: false,
  cartInitData: {
    storeConfig: {
      audioEyeEnabled: true,
      showStoreDistance: true,
      rewardsCertificateLoginNecessary: false,
      checkoutSynchronyRewardsEnabled: false
    }
  }
}

type actionType = Partial<typeof initialState>

export const userSlice = createSlice({
  name: 'cart',
  // state
  initialState,
  // sync action
  reducers: {
    save: (state, action: PayloadAction<actionType>) => {
      Object.assign(state, action.payload)
    }
  }
})

export const { save } = userSlice.actions

export default userSlice.reducer
