export const antdThemeConfig = {
  /******************* common ********************/
  /* red */
  primaryColor: '#e7131a',
  ghostColor: '#b30f14',
  errorColor: '#d30000',
  activeBackground: '#c20612',
  /* yellow */
  warningColor: '#eb703b',
  /* black */
  textNormalColor: '#2d2d2d',
  textLightColor: '#555555',
  textDarkColor: '#2d2d2d',
  /* gray */
  summaryBackground: '#f9f9f9',
  borderRadiusSM: 2,
  borderRadius: 4,
  borderLessLightColor: '#eee',
  borderLightColor1: '#dbdcde',
  borderLightColor2: '#ddd',
  borderLightColor3: '#dadedd',
  borderNormalColor: '#bbbbbb',
  borderDarkColor: '#707070',
  borderCheckboxColor: '#d9d9d9',
  borderDisabledColor: '#b2b2b2',
  arrowColor: '#1e1d1d',
  /* green */
  greenNormal: '#0ea200',
  /******************* private ********************/
  /* red */
  primaryLessDarkColor: '#cd2418',
  primaryDarkColor: '#b30f14',
  /* green */
  greenDark: '#058000',
  greenLessDark: '#1d862c',
  /* gray */
  borderMoreLightColor: '#e7e7e7',

  colorPrimaryHover: '#f53b3b',
  colorPrimaryBorderHover: '#f53b3b',

  activeBorderColor: '#ccc',
  defaultWhiteColor: '#fff',
  textPrimaryColor: '#676767',
  hoverBgColor: '#dddddd'
}
