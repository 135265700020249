import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { PLPTypings } from '@typings/plp'
import { includes, without } from 'lodash'

export const initialState = {
  currentStyleDetails: {} as Partial<PLPTypings.IStyleOptionArr>,
  activeThumbIndex: -1,
  compareItemArr: [] as string[],
  currentSelectedSkuIdForFinancing: '',
  adverts: [] as PLPTypings.IAdvert[],
  seoFacetValues: [] as string[]
}
type actionType = Partial<typeof initialState>

export const userSlice = createSlice({
  name: 'plp',
  // state
  initialState,
  // sync action
  reducers: {
    save: (
      state,
      action: {
        payload: actionType
        type: string
      }
    ) => {
      Object.assign(state, action.payload)
    },
    changeCompareArray: (state, actions: PayloadAction<string>) => {
      const { payload } = actions

      const hasCurrentCompareItem = includes(state.compareItemArr, payload)
      if (hasCurrentCompareItem) {
        state.compareItemArr = without(state.compareItemArr, payload)
      } else {
        state.compareItemArr.push(payload)
      }
    }
  }
})

export const { save, changeCompareArray } = userSlice.actions

export default userSlice.reducer
