import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useStore } from 'react-redux'

import algoliaPlp from './algolia-plp'
import cart from './cart'
import checkout from './checkout'
import payment from './checkout/payment'
import shipping from './checkout/shipping'
import storeComponent from './components/store'
import global from './global'
import header from './header'
import miniPdp from './mini-pdp'
import pdp from './pdp'
import plp from './plp'
import user from './user'

export let store

// Combine reducers explicitly
const rootReducer = combineReducers({
  global,
  pdp,
  user,
  checkout,
  header,
  storeComponent,
  plp,
  cart,
  shipping,
  payment,
  miniPdp,
  algoliaPlp
})

// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>

function initStore(preloadedState?: Partial<RootState>) {
  store = configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false
      })
  })
  return store
}

export const initializeStore = (preloadedState = undefined as any) => {
  let _store = initStore(preloadedState)

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState
    })
    // Reset the current store
    store = undefined
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export const useStoreInstance = useStore

export function useCreateStore(initialState) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return useMemo(() => initializeStore(initialState), [initialState])
}
