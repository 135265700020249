import '@gc/common-lib/utils/tailwind-util/GCColors.css' // import gc colors
import '../styles/global/global.scss'

import { GC_BREAK_POINTS_CONFIG } from '@constants/responsive'
import { LazyLoadProvider } from '@gc/common-lib/hoc/withLazyLoad'
import { logger } from '@gc/common-lib/utils/logger'
import { initGCBaseInterceptors } from '@helper/request'
import { save as globalSave } from '@store/global'
import { useCreateStore } from '@store/index'
import {
  hydrate,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { configResponsive } from 'ahooks'
import { ConfigProvider } from 'antd'
import { ClickToComponent } from 'click-to-react-component'
import { componentsDesignToken } from 'components/componentsDesignToken'
import type { NextWebVitalsMetric } from 'next/app'
import { Montserrat } from 'next/font/google'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { useTracking } from 'react-tracking'

configResponsive(GC_BREAK_POINTS_CONFIG)
// init GC base Interceptors
initGCBaseInterceptors()

const montserrat = Montserrat({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-montserrat'
})

export function reportWebVitals(metric: NextWebVitalsMetric) {
  const { value, name } = metric
  const transFormTime = value >= 100 ? value / 1000 : value
  console.log(`metric-${name}`, transFormTime?.toFixed(2))
}

export default function MyApp(props) {
  const { Component, pageProps } = props
  const page = useRouter().pathname
  const isHomePage = page.startsWith('/react-home')

  // Ensure that data is not shared between different users and requests
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: Infinity,
            gcTime: Infinity,
            retry: false,
            queryFn: ({ queryKey }) => {
              console.log('queryKey missing query Fn--->', queryKey)
            },
            refetchOnWindowFocus: false
          }
        }
      })
  )

  const store = useCreateStore(pageProps?.initialState)

  hydrate(queryClient, pageProps?.dehydratedState)

  const { Track } = useTracking(
    {},
    {
      dispatch(data) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer?.push(data)
      }
    }
  )

  useEffect(() => {
    if (isHomePage) {
      return
    }
    const registerServiceWorker = async () => {
      if ('serviceWorker' in navigator) {
        try {
          const registration = await navigator.serviceWorker.register(
            '/yottaa.service.worker.js',
            {
              scope: '/'
            }
          )
          if (registration.installing) {
            console.log('yottaaa Service worker installing')
          } else if (registration.waiting) {
            console.log('yottaa Service worker installed')
          } else if (registration.active) {
            console.log('yottaa Service worker active')
          }
        } catch (error) {
          console.error(`Registration failed with ${String(error)}`)
        }
      }
    }

    void registerServiceWorker()
  }, [])

  useEffect(() => {
    const handleRouteChangeError = (err, url) => {
      store.dispatch(
        globalSave({
          globalLoading: false
        })
      )

      logger.error({
        message: 'handleRouteChangeError error-->',
        err,
        url
      })
    }

    Router.events.on('routeChangeError', handleRouteChangeError)

    return () => {
      Router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [queryClient, store])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: `${montserrat.style.fontFamily}, sans-serif`,
                colorTextPlaceholder: '#BFBFBF',
                colorLink: 'inherit'
              },
              components: {
                ...componentsDesignToken
              }
            }}
          >
            <LazyLoadProvider>
              <Track>
                <main
                  id="app"
                  className={`tailwind ${montserrat.variable}  ${montserrat.className} next-app-container font-semibold`}
                >
                  <Component {...pageProps} />
                </main>
              </Track>
            </LazyLoadProvider>
          </ConfigProvider>
        </Provider>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
        <ClickToComponent editor="cursor" />
      </QueryClientProvider>
    </>
  )
}
