export const GLOBAL_CONFIG = [
  'enableClarip',
  'claripConsentJSUrl',
  'audioEyeEnabled',
  'enableYottaa',
  'loginMaxAttempts',
  'enableLoginRecaptcha',
  'recaptchaEnable',
  'storePickUpMode',
  'internationalContextEnabled'
]
export const CHECKOUT_CONFIG = ['QASValidation', 'SmartAddressEnable']

export const messageContentKeys = {
  pdpKeys:
    'GC_PDP_ENABLE_SERIALIZED_COMPARE_BUTTON,GC_PDP_WARRANTY_LIST,GC_PDP_INSTORE_PICKUP_TODAY_MSG,GC_PDP_CURBSIDE_PICKUP_TODAY_MSG,GC_PDP_INSTORE_PICKUP_UNAVAIL_MSG,GC_PDP_CURBSIDE_PICKUP_UNAVAIL_MSG,GC_PDP_BOTH_AT_STORE_TODAY_MSG,GC_PDP_BOTH_AT_STORE_UNAVAIL_MSG,GC_PDP_STORE_FINDER_NOT_AVAILABLE_PICKUP_MSG,GC_PDP_INVENTORY_NOT_AVAILABLE_MESSAGE,GC_PDP_STORE_FREE_DELIVERY_PRICE',
  GCHeaderFooterKeys:
    'GC_HEADER_FLYOUT_ACCOUNT_LOGGED_IN_MENUS,GC_HEADER_FLYOUT_ACCOUNT_LOGGED_OUT_MENUS,GC_DEFAULT_PRE_HEADER_ROLL,GC_HEADER_LIVE_HELP_TOP_MESSAGE,GC_HEADER_LIVE_HELP_PHONE_NUMBER,GC_HEADER_LIVE_HELP_ANOTHER_PHONE_NUMBER,GC_HEADER_LIVE_HELP_BOTTOM_LINK,GC_DESKTOP_FOOTER_EMAIL_SIGNUP_HEADER_TEXT,GC_DESKTOP_FOOTER_EMAIL_SIGNUP_INPUT_TITLE,GC_DESKTOP_FOOTER_EMAIL_SIGNUP_BUTTON_LABEL,GC_HEADER_LIVE_HELP_SERVER_CONTACT_TIME,SERIAL_ITEM_POUNDS_TEXT_KEY',
  mfFooterKeys: 'MF_CHECKOUT_FOOTER_LINK,MF_FOOTER_LINK',
  GCCheckoutKeys:
    'GC_CHECKOUT_FOOTER_LEFT_CONTENT,GC_CHECKOUT_FOOTER_RIGHT_LINK,GC_CHECKOUT_ROUND_UP_MODAL_CONTENT',
  GCSignUpTermsKeys: 'GC_SIGNUP_TERMS_AND_CONDITIONS',
  MFMobileAPPFinancingKeys:
    'PDP_SPECIAL_FINANCE_DISC_STAT,PDP_MONTHLY_FINANCE_DISC_STAT'
}

export const nameValueContentKeys = {
  pdpNameValueKeys:
    'GC-PDP-lease-to-own-modal-header,GC-PDP-lease-to-own-modal-spot-light,GC-PDP-lease-to-own-modal-estimate,GC-PDP-lease-to-own-modal-process,GC-PDP-lease-to-own-modal-faq,GC-PDP-lease-to-own-modal-fine-copy,GC-PDP-lease-to-own-modal-footer,GC-PDP-lease-to-own-modal-style,GC-PDP-warranty-add-on-right-rail,GC-PDP-lesson-modal-questions,GC-PDP-lesson-modal-why-guitar-lessons,GC-PDP-add-to-cart-jump-path',
  kiboNameValueKeys:
    'GC_KIBO_CHANNEL_KEY,GC_KIBO_PDP_REQUEST_KEY,GC_KIBO_PLP_REQUEST_KEY',
  GCCartPageContentKeys:
    'GC-CART-rewords-modal-content,GC-CART-pro-coverage-modal-content,CART_EMPTY_SERVER_CONTACT_TIME_DEFAULT,GC-CART-KIBO-PLACEMENTS,GC-EMPTY-CART-KIBO-PLACEMENTS,GC-CART-KIBO-TITLE,GC-EMPTY-CART-KIBO-TITLE'
}

export const BBB_LINKS_DEFAULT = `<div class="bbb-links">
  <a class="magicLink targetBlank bbb-link" href="http://www.bbb.org/" target="_blank">
    <span class="bbb-img">Better Business Bureau <span class="screen-reader-only">(opens in new window)</span></span>
	</a>
  <a class="bbb-caru-link" target="_blank" rel="nofollow" aria-label="Click for the BBB CARU Business Review" href="https://caru.bbb.org/general.aspx?id=2140265902">
    <img alt="Click for the BBB Business Review" src="//static.guitarcenter.com/static/gc/bbb-footer/bbb-caru.png" />
  </a>
</div>`

export const MOBILE_CONNECT_LINKS_DEFAULT = `<ul class="legal"><li> <a data-ajax="false" href="/pages/terms-of-use" class="ui-link">Terms of Use</a> </li><li> <a data-ajax="false" href="/pages/purchase-terms" class="ui-link">Terms &amp; Conditions</a> </li><li> <a data-ajax="false" href="/pages/privacy-rights-policy" class="ui-link">Privacy Policy</a> </li><li> <a data-ajax="false" href="/pages/Accessibility-Statement.gc" class="ui-link">Accessibility Statement</a></li></ul>
<ul class="legal"><li><a data-ajax="false" href="/pages/ca-transparency-supply-chain" class="ui-link">CA Transparency Act</a></li><li><a data-ajax="false" href="/pages/data-request?clarip=1" class="ui-link do-not-sell-link">Do Not Sell or Share My Info</a></li><li><a data-ajax="false" href="/pages/data-request" class="ui-link data-rights-request-link">Data Rights Request</a></li><li><a data-ajax="false" href="#" id="consent-management-tool" class="ui-link cookie-preferences-link">Cookie Preferences</a></li></ul>`

export const DEFAULT_RATE_DATA = { roundMethod: 2.0, value: 1.0, symbol: '$' }
export const DEFAULT_LCP_DATA = { frontLoadCoefficient: 1 }

export const GC_HEADER_FLYOUT_ACCOUNT_LOGGED_IN_MENUS_DEFAULT = `<div class="my-account-links-wrap">
<h3>My Account</h3>
<ul class="my-account-links">
   <li><a href="/myaccount/order/history">Track Order</a></li>
   <li><a href="/myaccount/overview">Account Overview</a></li>
   <li class="online-lessons-link">
      <a href="/myaccount/lessons-online">Student Portal
      </a>
   </li>
   <li class="digital-practice-link">
      <a href="/myaccount/my-lessons">Level Up (Digital Practice Support)
      </a>
   </li>
   <li><a href="/wishlists">Wish List</a></li>
   <li><a title="My Subscriptions" href="/myaccount/alerts">Alerts</a></li>
   <li><a href="/myaccount/address">Address Book</a></li>
   <li><a href="/myaccount/payment">Payment Methods</a></li>
   <li><a href="/Gift-Card/Balance.gc">Gift Card Balances</a></li>
   <li><a href="/customer-service">Need Help?</a></li>
   <li><a href="https://www.mysynchrony.com/cmp-index.html?market=music&amp;store=guitarcenter" target="_blank" aria-describedby="audioeye_new_window_message">Pay Gear Card &amp; Manage</a></li>
   <li><a href="https://www.myfortiva.com/my-account/login" target="_blank" aria-describedby="audioeye_new_window_message">Pay Essential Card</a></li>
</ul>
</div>`
export const GC_HEADER_FLYOUT_ACCOUNT_LOGGED_OUT_MENUS_DEFAULT = `<div class="my-account-links-wrap">
<h3>My Account</h3>
<ul class="my-account-links">
   <li><a href="/trackorder">Track Order</a></li>
   <li><a href="/myaccount/overview">Account Overview</a></li>
   <li class="online-lessons-link">
      <a href="/myaccount/lessons-online">Student Portal
      </a>
   </li>
   <li class="digital-practice-link">
      <a href="/myaccount/my-lessons">Level Up (Digital Practice Support)
      </a>
   </li>
   <li><a href="/wishlists">Wish List</a></li>
   <li><a href="/myaccount/alerts">Alerts</a></li>
   <li><a href="/myaccount/my-subscriptions">My Subscriptions</a></li>
   <li><a href="/myaccount/address">Address Book</a></li>
   <li><a href="/myaccount/payment">Payment Methods</a></li>
   <li><a href="/Gift-Card/Balance.gc">Gift Card Balances</a></li>
   <li><a href="/customer-service">Need Help?</a></li>
   <li><a href="https://www.mysynchrony.com/cmp-index.html?market=music&amp;store=guitarcenter" target="_blank" aria-describedby="audioeye_new_window_message">Pay Gear Card &amp; Manage</a></li>
   <li><a href="https://www.myfortiva.com/my-account/login" target="_blank" aria-describedby="audioeye_new_window_message">Pay Essential Card</a></li>
</ul>
</div>`
export const GC_HEADER_LIVE_HELP_TOP_MESSAGE_DEFAULT = `Hey there.<br>How can we help you out?<br>We're here for your gear needs.`
export const GC_HEADER_LIVE_HELP_SERVER_CONTACT_TIME_DEFAULT = `<div class="text-center text-[15px]">
<div style='margin-bottom:22px;'>MON - FRI 5 AM - 8 PM PT</div>
<div style='margin-bottom:22px;'>SAT 6 AM - 6 PM PT</div>
<div style='margin-bottom:22px;'>SUN 6 AM - 5 PM PT</div>
</div>`
export const GC_HEADER_LIVE_HELP_PHONE_NUMBER_DEFAULT = `866-498-7882`
export const GC_HEADER_LIVE_HELP_ANOTHER_PHONE_NUMBER_DEFAULT = `<a class="live-help_link" href="tel:877-687-5402"><span class="lbl">Español</span><span>&nbsp877-687-5402</span></a>`
export const GC_HEADER_LIVE_HELP_BOTTOM_LINK_DEFAULT = `<ul><li class="mb-5"><a href="/trackorder">Track Order</a></li><li class="mb-5"><a href="/pages/return-policy">Returns</a></li><li class="mb-5"><a href="/customer-service">FAQs</a></li><li class="mb-5"><a href="https://stores.guitarcenter.com/search.html">Store Locator</a></li><li class="mb-5"><a href="/Services/Lessons">Guitar Center Lessons</a></li></ul>`
export const GC_DESKTOP_FOOTER_EMAIL_SIGNUP_HEADER_TEXT_DEFAULT =
  'Be the first to know about exclusive offers, tips and more.'
export const GC_DESKTOP_FOOTER_EMAIL_SIGNUP_INPUT_TITLE_DEFAULT =
  'Guitar Center Email News Signup'
export const GC_DESKTOP_FOOTER_EMAIL_SIGNUP_BUTTON_LABEL_DEFAULT = 'Sign Up'
export const GC_SIGNUP_TERMS_AND_CONDITIONS_DEFAULT = `By creating an account, you have agreed to Guitar Center's <a href="/pages/purchase-terms">Purchase Terms and Conditions</a>, you have reviewed Guitar Center's <a href="/pages/privacy-rights-policy">Privacy Policy</a> and you have agreed to receive Guitar Center's exclusive email offers, promotions and other marketing materials. To manage your marketing choices please access Communication Preferences under your My Account page, the Marketing Choice section of the Guitar Center's Privacy Policy or Call Guitar Center Customer Service. Message and data rates may apply when including a phone number.`
export const GC_CHECKOUT_SINGLE_USE_DISCLAIMER_TEXT =
  'Certificates good for one-time use only. Unused balances are not refundable.'

export const GC_GEO_LOCATION_EXPIRED_TIME = 'gc_geo_location_expired_time'
