import type { ICartItem } from '@gc/common-lib/api/cart'
import { createSlice } from '@reduxjs/toolkit'
export const initialState = {
  skuId: '',
  productId: '',
  defaultGlobalLessonId: '',
  selectWarrantyId: '',
  productQuantity: 1,
  popupModal: [] as string[],
  merchantId: '',
  bundleItems: [] as ICartItem[],
  addLessonToCartStatus: 'idle',
  addedToWishListName: '',
  isOnlyChooseInStore: false,
  serialItemEnable: 'false',
  isMiniPDP: false
}
type actionType = Partial<typeof initialState>

export const userSlice = createSlice({
  name: 'pdp',
  // state
  initialState,
  // sync action
  reducers: {
    save: (
      state,
      action: {
        payload: actionType
        type: string
      }
    ) => {
      Object.assign(state, action.payload)
    }
  }
})

export const { save } = userSlice.actions

export default userSlice.reducer
