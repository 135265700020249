import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  headerBottomActiveArray: [] as string[]
}
type actionType = Partial<typeof initialState>

export const headerSlice = createSlice({
  name: 'header',
  // state
  initialState,
  // sync action
  reducers: {
    save: (state, action: PayloadAction<actionType>) => {
      Object.assign(state, action.payload)
    }
  }
})

export const { save } = headerSlice.actions

export default headerSlice.reducer
