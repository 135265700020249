import { isString } from 'lodash'
import { LRUCache } from 'lru-cache'

const cache = new LRUCache<string, any>({
  max: 20,

  ttl: 1000 * 30,
  ttlAutopurge: true,

  allowStale: false,
  updateAgeOnGet: false,
  updateAgeOnHas: false
})

export const createCacheableRequest = <P extends any[], R>(
  fn: (...args: P) => Promise<R>,
  createCacheKey: (...args: P) => string
) => {
  const cacheableFn: (...args: P) => Promise<R> = async (...args) => {
    const cacheKey = createCacheKey(...args)
    const cacheValue: R = cache.get(cacheKey)

    if (cacheValue) {
      return isString(cacheValue) ? (JSON.parse(cacheValue) as R) : cacheValue
    }

    const promise = fn(...args)
    cache.set(cacheKey, promise)
    const result = await promise
    if (result) {
      cache.set(cacheKey, JSON.stringify(result))
    } else {
      cache.delete(cacheKey)
    }
    return result
  }

  return cacheableFn
}
