import type { UserTypings } from '@gc/common-lib/typings/user'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  locale: {} as UserTypings.IUserLocale,
  geoLocation: null as any as Pick<
    GeolocationCoordinates,
    'latitude' | 'longitude'
  >
}
type actionType = Partial<typeof initialState>

export const userSlice = createSlice({
  name: 'user',
  // state
  initialState,
  // sync action
  reducers: {
    save: (state, action: PayloadAction<actionType>) => {
      Object.assign(state, action.payload)
    }
  }
})

export const { save } = userSlice.actions

export default userSlice.reducer
