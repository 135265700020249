import type { ThemeConfig } from 'antd'

import { antdThemeConfig } from './antdThemeConfig'

export const componentsDesignToken: ThemeConfig['components'] = {
  Checkbox: {
    colorBorder: antdThemeConfig.borderDarkColor,
    colorPrimary: antdThemeConfig.primaryColor,
    colorBgContainer: 'transparent',
    controlInteractiveSize: 20,
    borderRadius: antdThemeConfig.borderRadius,
    colorPrimaryHover: antdThemeConfig.primaryColor,
    colorPrimaryActive: antdThemeConfig.primaryColor,
    colorBgContainerDisabled: antdThemeConfig.borderDisabledColor,
    colorTextDisabled: antdThemeConfig.borderLessLightColor
  },
  Select: {
    fontSize: 16,
    selectorBg: 'white',
    optionHeight: 32,
    // @ts-expect-error
    lineHeight: '22px',
    optionSelectedBg: antdThemeConfig.summaryBackground,
    optionPadding: '5px 12px',
    optionFontSize: 16,
    paddingXXS: 0,
    colorBorder: antdThemeConfig.borderNormalColor,
    hoverBorderColor: antdThemeConfig.borderNormalColor,
    activeBorderColor: antdThemeConfig.borderNormalColor,
    activeOutlineColor: 'none',
    optionActiveBg: antdThemeConfig.summaryBackground,
    borderRadius: antdThemeConfig.borderRadius,
    colorIcon: antdThemeConfig.arrowColor,
    colorIconHover: antdThemeConfig.arrowColor,
    colorTextPlaceholder: antdThemeConfig.textNormalColor,
    colorBgContainerDisabled: 'white',
    colorTextDisabled: antdThemeConfig.borderNormalColor,
    colorError: antdThemeConfig.errorColor,
    colorErrorBorder: antdThemeConfig.errorColor,
    borderRadiusLG: antdThemeConfig.borderRadiusSM,
    borderRadiusSM: antdThemeConfig.borderRadiusSM,
    optionSelectedColor: antdThemeConfig.textNormalColor,
    paddingXS: 0
  },
  Radio: {
    // radio dot size
    dotSize: 14,
    // radio size
    radioSize: 22,
    // border width of radio outline
    lineWidth: 2,
    // border color of radio outline, not hovered border color
    colorBorder: antdThemeConfig.borderCheckboxColor,
    // color of radio outline when hovered, and checked border color
    colorPrimary: antdThemeConfig.primaryColor,
    // color of radio text when disabled
    colorTextDisabled: antdThemeConfig.borderDisabledColor
  },
  Pagination: {
    // minWidth: 20, height: 20, lineHeight: 20,
    itemSize: 20,
    // border width, set to 0 to remove border
    lineWidth: 0,
    // text color, including next/prev text color
    colorPrimary: antdThemeConfig.textPrimaryColor,
    // border radius of hovered and normal item
    borderRadius: 2,
    // next/prev hover background color
    colorBgTextHover: antdThemeConfig.hoverBgColor,
    // ellipsis text color
    colorTextDisabled: antdThemeConfig.textPrimaryColor
  },
  Modal: {
    marginMD: 0,
    marginSM: 0,
    borderRadius: 2,
    borderRadiusLG: 2,
    borderRadiusOuter: 2,
    borderRadiusSM: 2,
    borderRadiusXS: 2,
    padding: 0,
    paddingLG: 0,
    paddingXS: 0,
    paddingMD: 0,
    paddingSM: 0,
    paddingXL: 0,
    paddingXXS: 0,
    paddingContentHorizontal: 0,
    paddingContentVertical: 0,
    paddingContentHorizontalLG: 0,
    paddingContentVerticalLG: 0,
    paddingContentHorizontalSM: 0,
    paddingContentVerticalSM: 0
  },
  InputNumber: {
    colorTextPlaceholder: '#9CA3AF'
  },
  Input: {
    hoverBorderColor: antdThemeConfig.activeBorderColor,
    borderRadius: 4,
    colorBgContainerDisabled: 'inherit',
    colorTextDisabled: antdThemeConfig.borderNormalColor,
    activeBorderColor: antdThemeConfig.activeBorderColor,
    activeShadow: 'none'
  },
  Collapse: {
    // reset the padding of collapse close icon from its' left side
    marginSM: 0
  },
  Button: {
    colorText: antdThemeConfig.defaultWhiteColor,
    borderRadius: antdThemeConfig.borderRadius,
    defaultColor: antdThemeConfig.defaultWhiteColor,
    defaultBorderColor: antdThemeConfig.borderNormalColor,
    defaultBg: 'white',
    defaultHoverColor: antdThemeConfig.ghostColor,
    defaultHoverBorderColor: antdThemeConfig.ghostColor,
    colorPrimary: antdThemeConfig.primaryColor,
    colorPrimaryBg: antdThemeConfig.primaryColor,
    colorPrimaryHover: antdThemeConfig.colorPrimaryHover,
    colorPrimaryBorderHover: antdThemeConfig.colorPrimaryBorderHover,
    ghostBg: 'transparent',
    defaultGhostColor: antdThemeConfig.ghostColor,
    defaultGhostBorderColor: antdThemeConfig.ghostColor,
    defaultActiveBorderColor: antdThemeConfig.activeBackground,
    colorPrimaryActive: antdThemeConfig.activeBackground,
    colorTextDisabled: antdThemeConfig.borderLessLightColor,
    borderColorDisabled: antdThemeConfig.borderLightColor2,
    colorBgContainerDisabled: antdThemeConfig.borderDisabledColor
  },
  Switch: {
    colorBorder: antdThemeConfig.borderLessLightColor,
    colorPrimary: antdThemeConfig.primaryDarkColor,
    colorBgContainer: 'transparent',
    // switch handle icon size
    handleSize: 18,
    // switch control height
    controlHeight: 14,
    // switch track min width
    trackMinWidth: 36,
    // switch track height
    trackHeight: 14,
    // switch handle background color
    colorTextQuaternary: antdThemeConfig.borderLessLightColor,
    // switch handle default background color
    handleBg: antdThemeConfig.defaultWhiteColor,
    handleShadow: '0 2px 4px 0 rgba(0, 35, 11, 0.2)',
    // switch icon top offset
    trackPadding: -3,
    // switch handle hover background color
    colorPrimaryHover: antdThemeConfig.primaryDarkColor,
    // switch icon active background color
    colorPrimaryActive: antdThemeConfig.primaryDarkColor
  },
  Form: {
    // form item margin bottom
    itemMarginBottom: 16,
    // form item font size
    fontSize: 12,
    // form item warning color
    colorWarning: antdThemeConfig.warningColor,
    // form item error color
    colorError: antdThemeConfig.errorColor,
    // form item line height
    lineHeight: 1.5
  },
  Rate: {
    // gap of rate stars, default is 8, now is 4
    marginXS: 4
  }
}
