import { message } from 'antd'
import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios'
import axios from 'axios'
import * as JSONBig from 'json-bigint'
import { assign, filter, includes } from 'lodash'

import {
  PAGE_PROCESS_CHECKOUT_LOGIN,
  PAGE_PROCESS_HOME
} from '../constants/checkout'
import { isBrowser } from '../utils/browser'
import { devHeaders } from '../utils/fetch'
import { logger } from '../utils/logger'

export const TIME_OUT_VALUE = 20000
export interface IAxiosResponse {
  statusCode: string
  statusMessage: string
  [key: string]: any
}
declare module 'axios' {
  export interface AxiosRequestConfig {
    needSession?: boolean
    needLoading?: boolean
    clearSession?: boolean
    needSPAFlag?: boolean
    requestKey?: string
    needShowErrorMessage?: boolean
    showSessionTimeOutMessage?: boolean
    needWholeErrorData?: boolean
    isGCCheckoutRequest?: boolean
  }
}
// cancel token
let pendingRequest: string[] = []
const requestController = new AbortController()

function cancelRequest(config: AxiosRequestConfig) {
  const { url, params, data, method } = config
  const requestKey = `${url}${JSON.stringify(params) || ''}${
    JSON.stringify(data) || ''
  }${method}`
  if (includes(pendingRequest, requestKey)) {
    config.signal = requestController.signal
    requestController.abort()
  } else {
    config.requestKey = requestKey
    pendingRequest.push(requestKey)
  }
}
function finishRequest(config: AxiosRequestConfig) {
  const { requestKey } = config
  pendingRequest = filter(pendingRequest, key => requestKey !== key)
}

function handlePageSessionExpire(res) {
  // when user session expired, redirect to cart page
  if (res?.data?.sessionTimedOut === 'true') {
    window.localStorage.removeItem('dynSessConf')

    if (res?.config?.showSessionTimeOutMessage !== false) {
      const defaultMessage =
        "You've been signed out for your security. Sign back in to continue."
      // const { message } = await import('antd')
      message.error({
        content: res.data.alertMessage ?? defaultMessage,
        duration: 10
      })
    }
    const orderState = res?.data?.path ?? res?.data?.orderState

    if (orderState) {
      if (orderState === PAGE_PROCESS_HOME) {
        window.location.href = '/'
      } else if (orderState === PAGE_PROCESS_CHECKOUT_LOGIN) {
        window.location.href = '/checkout/login'
      } else {
        window.location.href = `/${orderState}`
      }
    }
  }
}

// base server
const ATGService: AxiosInstance = axios.create({
  timeout: TIME_OUT_VALUE,
  baseURL: '/rest/model',
  headers: devHeaders,
  transformResponse: [
    data => {
      try {
        // resolve the sessionNumber from the response
        return JSONBig.parse(data)
      } catch (error) {
        logger.error({
          message: 'ATGService transformResponse failed',
          error
        })
        return data
      }
    }
  ]
})

ATGService.interceptors.response.use(
  (res: AxiosResponse<IAxiosResponse>) => {
    // handle checkout page request
    handlePageSessionExpire(res)
    // cancel pending
    finishRequest(res.config)
    return res
  },
  (error: any) => {
    // cancel pending
    finishRequest(error?.config || {})
    logger.error({
      message: 'ATGService request failed',
      error
    })
    return Promise.reject(error as Error)
  }
)

// third party server
export const thirdPartyService: AxiosInstance = axios.create({
  timeout: TIME_OUT_VALUE,
  headers: devHeaders
})

thirdPartyService.interceptors.response.use(
  res => res,
  error => {
    logger.error({
      message: 'thirdPartyService request failed',
      error
    })
    return Promise.reject(error as Error)
  }
)

export interface CustomRequestConfig extends AxiosRequestConfig {
  needSession?: boolean
  needSPAFlag?: boolean
  _dynSessConf?: string | null
}

export const initCommonRequestInterceptors = (): number => {
  return ATGService.interceptors.request.use(
    (config: InternalAxiosRequestConfig<CustomRequestConfig>) => {
      cancelRequest(config)

      const { method, needSession = false, needSPAFlag = true } = config

      if (method === 'get') {
        config.params = config.params || {}
        if (needSession) {
          const sessionConfirmationNumber = isBrowser()
            ? localStorage.getItem('dynSessConf')
            : ''
          config.params._dynSessConf = sessionConfirmationNumber
        }
      }

      if (needSPAFlag) {
        config.params = config.params || {}
        config.params.SPA = true
      }

      if (method === 'post') {
        config.data = config.data || {}
        if (needSession) {
          const sessionConfirmationNumber = isBrowser()
            ? localStorage.getItem('dynSessConf')
            : ''
          assign(config.data, { _dynSessConf: sessionConfirmationNumber })
        }
      }
      return config
    },
    (err: Error) => {
      return Promise.reject(err)
    }
  )
}

export default ATGService
