import {
  BBB_LINKS_DEFAULT,
  GC_DESKTOP_FOOTER_EMAIL_SIGNUP_BUTTON_LABEL_DEFAULT,
  GC_DESKTOP_FOOTER_EMAIL_SIGNUP_HEADER_TEXT_DEFAULT,
  GC_DESKTOP_FOOTER_EMAIL_SIGNUP_INPUT_TITLE_DEFAULT,
  GC_HEADER_FLYOUT_ACCOUNT_LOGGED_IN_MENUS_DEFAULT,
  GC_HEADER_FLYOUT_ACCOUNT_LOGGED_OUT_MENUS_DEFAULT,
  GC_HEADER_LIVE_HELP_ANOTHER_PHONE_NUMBER_DEFAULT,
  GC_HEADER_LIVE_HELP_BOTTOM_LINK_DEFAULT,
  GC_HEADER_LIVE_HELP_PHONE_NUMBER_DEFAULT,
  GC_HEADER_LIVE_HELP_SERVER_CONTACT_TIME_DEFAULT,
  GC_HEADER_LIVE_HELP_TOP_MESSAGE_DEFAULT,
  GLOBAL_CONFIG,
  messageContentKeys,
  MOBILE_CONNECT_LINKS_DEFAULT
} from '../../constants/global'
import { createCacheableRequest } from '../../server/LRUCache'
import { serverRequest } from '../../utils/fetch'
import { logger } from '../../utils/logger'
import type { IServerConfigData } from '../header'
import ATGService from '../request'

export const getBCCMultiMessageContent = async keys => {
  const res = await ATGService({
    url: '/ngp/rest/actor/DropletActor/MultiMessageContentDroplet',
    method: 'GET',
    params: { keys }
  })
  return res?.data
}

export type getBCCMultiMessageContentType = (
  keys: string
) => Promise<Record<string, unknown> | undefined>

export const getBCCMultiMessageContentByServerSide = createCacheableRequest(
  async (keys: string) => {
    try {
      const res = await serverRequest({
        url: '/rest/model/ngp/rest/actor/DropletActor/MultiMessageContentDroplet',
        method: 'GET',
        params: { keys }
      })
      return res?.data
    } catch (error) {
      logger.error({
        message: `Failed getBCCMultiMessageContentByServerSide API request`,
        keys,
        error
      })
    }
  },
  keys => `getBCCMultiMessageContentByServerSide_${keys}`
)

export const getBCCMultiNameValueContent = async keys => {
  const res = await ATGService({
    url: '/ngp/rest/actor/DropletActor/MultiNameValuePairDroplet',
    method: 'GET',
    params: { keys }
  })
  return res?.data
}

export const getBCCMultiNameValueContentByServerSide = createCacheableRequest(
  async (keys: string) => {
    try {
      const res = await serverRequest({
        url: '/rest/model/ngp/rest/actor/DropletActor/MultiNameValuePairDroplet',
        method: 'GET',
        params: { keys }
      })
      return res?.data
    } catch (error) {
      logger.error({
        message: `Failed getBCCMultiNameValueContentByServerSide API request`,
        keys,
        error
      })
    }
  },
  keys => `getBCCMultiMessageContentByServerSide_${keys}`
)
export const getSessionNumber = async (
  isAddReferrerParams = false
): Promise<void> => {
  let decodedUrl = window.location.href
  try {
    decodedUrl = decodeURIComponent(decodedUrl)
  } catch (error) {
    logger.error({
      message: `Failed getSessionNumber URL decoding`,
      error,
      decodedUrl,
      isAddReferrerParams
    })
  }
  const searchParams = new URLSearchParams(new URL(decodedUrl).search)
  /* The code is retrieving specific query parameters from the URL and storing their values in
 variables. */
  const combinedParams = new URLSearchParams()
  if (searchParams.has('source')) {
    combinedParams.set('source', searchParams.get('source') as string)
  }
  if (searchParams.has('src')) {
    combinedParams.set('src', searchParams.get('src') as string)
  }
  if (searchParams.has('SOURCE')) {
    combinedParams.set('SOURCE', searchParams.get('SOURCE') as string)
  }
  if (searchParams.has('SRC')) {
    combinedParams.set('SRC', searchParams.get('SRC') as string)
  }
  if (searchParams.has('CJAID')) {
    combinedParams.set('CJAID', searchParams.get('CJAID') as string)
  }
  if (searchParams.has('CJPID')) {
    combinedParams.set('CJPID', searchParams.get('CJPID') as string)
  }
  const {
    data: { sessionConfirmationNumber }
  } = await ATGService({
    url: `/atg/rest/SessionConfirmationActor/getSessionConfirmationNumber?${combinedParams}`,
    method: 'get',
    params: {
      referralCode: isAddReferrerParams ? document.referrer : undefined
    }
  })
  localStorage.setItem('dynSessConf', sessionConfirmationNumber)
}

const defaultHeaderFooterData = {
  recaptchaEnable: true,
  menu: [],
  footer: {
    phoneNumber: '',
    footerLinkConfig: [],
    bbbLinksHTML: BBB_LINKS_DEFAULT,
    footerLegal: '',
    spaPhoneNumber: '',
    mobileConnectLinks: MOBILE_CONNECT_LINKS_DEFAULT
  },
  messageContent: {
    contentItem: {
      GC_HEADER_FLYOUT_ACCOUNT_LOGGED_IN_MENUS:
        GC_HEADER_FLYOUT_ACCOUNT_LOGGED_IN_MENUS_DEFAULT,
      GC_HEADER_FLYOUT_ACCOUNT_LOGGED_OUT_MENUS:
        GC_HEADER_FLYOUT_ACCOUNT_LOGGED_OUT_MENUS_DEFAULT,
      GC_DEFAULT_PRE_HEADER_ROLL: '',
      GC_HEADER_LIVE_HELP_TOP_MESSAGE: GC_HEADER_LIVE_HELP_TOP_MESSAGE_DEFAULT,
      GC_HEADER_LIVE_HELP_PHONE_NUMBER:
        GC_HEADER_LIVE_HELP_PHONE_NUMBER_DEFAULT,
      GC_HEADER_LIVE_HELP_ANOTHER_PHONE_NUMBER:
        GC_HEADER_LIVE_HELP_ANOTHER_PHONE_NUMBER_DEFAULT,
      GC_HEADER_LIVE_HELP_BOTTOM_LINK: GC_HEADER_LIVE_HELP_BOTTOM_LINK_DEFAULT,
      GC_HEADER_LIVE_HELP_SERVER_CONTACT_TIME:
        GC_HEADER_LIVE_HELP_SERVER_CONTACT_TIME_DEFAULT,
      GC_DESKTOP_FOOTER_EMAIL_SIGNUP_HEADER_TEXT:
        GC_DESKTOP_FOOTER_EMAIL_SIGNUP_HEADER_TEXT_DEFAULT,
      GC_DESKTOP_FOOTER_EMAIL_SIGNUP_INPUT_TITLE:
        GC_DESKTOP_FOOTER_EMAIL_SIGNUP_INPUT_TITLE_DEFAULT,
      GC_DESKTOP_FOOTER_EMAIL_SIGNUP_BUTTON_LABEL:
        GC_DESKTOP_FOOTER_EMAIL_SIGNUP_BUTTON_LABEL_DEFAULT,
      SERIAL_ITEM_POUNDS_TEXT_KEY: 'lb'
    }
  },
  storeConfig: {
    value: {
      claripConsentJSUrl: 'https://cdn.clarip.com/gci/clarip_consent.js',
      enableClarip: true,
      audioEyeEnabled: true,
      loginMaxAttempts: 3,
      enableLoginRecaptcha: true,
      internationalContextEnabled: false
    }
  }
}

interface IGetHeaderFooterData {
  status: string
  data: IServerConfigData
  message?: string
}

export const getHeaderFooterDataBySeverSide = createCacheableRequest(
  async (): Promise<IGetHeaderFooterData | undefined> => {
    const url = `/rest/model/ngp/rest/actor/DropletActor/getServerConfigData`
    const params = {
      params: {
        hiddenForInternational: false,
        messageContentKeys: messageContentKeys.GCHeaderFooterKeys,
        storeConfigKeys: GLOBAL_CONFIG.join(',')
      }
    }

    try {
      const res = await serverRequest.get(url, params)

      if (res.status !== 200) {
        logger.error({
          message:
            'Failed to get header footer data on sever side with non 200 status',
          params,
          status: res.status,
          statusText: res.statusText,
          data: res.data,
          url
        })
      }

      return res.data
    } catch (error) {
      logger.error({
        message: 'Failed to get header footer data on sever side',
        params,
        url,
        error
      })
    }
  },
  () => `getHeaderFooterDataBySeverSide_${false}`
)

export const getHeaderFooterDataByATGService =
  async (): Promise<IGetHeaderFooterData> => {
    const url = `/ngp/rest/actor/DropletActor/getServerConfigData`
    const params = {
      params: {
        hiddenForInternational: false,
        messageContentKeys: messageContentKeys.GCHeaderFooterKeys,
        storeConfigKeys: GLOBAL_CONFIG.join(',')
      }
    }
    const res = await ATGService.get(url, params)

    if (res.status !== 200) {
      logger.error(
        logger.error({
          message:
            'Failed to get header footer data on ATGService with non 200 status',
          params,
          status: res.status,
          statusText: res.statusText,
          data: res.data,
          url
        })
      )
    }

    return res.data
  }

export type getHeaderFooterInfoByAPIType = (
  international?: boolean
) => Promise<IServerConfigData>

export const getHeaderFooterInfoByAPI: getHeaderFooterInfoByAPIType =
  async () => {
    const { data } = await getHeaderFooterDataByATGService()
    return data || defaultHeaderFooterData
  }

export const getHeaderFooterInfoByAPIServer: getHeaderFooterInfoByAPIType =
  async () => {
    const { data } = (await getHeaderFooterDataBySeverSide()) as {
      data: IServerConfigData
    }
    return data
  }
