import pino from 'pino'

export const logger = pino({
  level: 'info',
  messageKey: 'message',
  errorKey: 'message',
  timestamp: pino.stdTimeFunctions.isoTime,
  formatters: {
    level(label) {
      return { level: label }
    },
    bindings() {
      return {
        env: process.env.environment
      }
    }
  }
})

export const nextApiLogger = logger.child({
  name: '##Next Page Api##'
})

export const redisLogger = logger.child({
  name: '##Server Redis Client##'
})
