import type { CartTypings } from '@gc/common-lib/typings/cart'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CheckoutTypings } from '@typings/checkout'

const initialState = {
  shippingMethod: null as CheckoutTypings.IShippingMethod | null,
  shippingCommerceItemsData: null as CartTypings.IShippingCommerceItem[] | null,
  shippingOrderLevelPromotionsData: null as
    | CartTypings.IOrderLevelPromotionsItem[]
    | null
}

type actionType = Partial<typeof initialState>

export const userSlice = createSlice({
  name: 'shipping',
  // state
  initialState,
  // sync action
  reducers: {
    saveShipping: (state, action: PayloadAction<actionType>) => {
      Object.assign(state, action.payload)
    },
    resetShippingStore: () => initialState
  }
})

export const { saveShipping, resetShippingStore } = userSlice.actions

export default userSlice.reducer
