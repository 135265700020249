import { getSessionNumber } from '@gc/common-lib/api/global'
import type { IAxiosResponse } from '@gc/common-lib/api/request'
import ATGService, {
  initCommonRequestInterceptors
} from '@gc/common-lib/api/request'
import { friendlyErrorMessage } from '@gc/common-lib/constants/friendlyErrorMessage'
import { logger } from '@gc/common-lib/utils/logger'
import { save } from '@store/global'
import { store } from '@store/index'
import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { forEach, get, includes } from 'lodash'
import { createElement } from 'react'

export const isClient = typeof window !== 'undefined'

export function initGCBaseInterceptors(): void {
  const sessionCache = {
    getSession: () => localStorage.getItem('dynSessConf'),
    removeSession: () => localStorage.removeItem('dynSessConf'),
    fetched: false,
    isSessionFetching: false,
    pendingRequest: [] as (() => void)[]
  }

  // Set common request interceptors
  initCommonRequestInterceptors()
  interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
    needLoading?: boolean
    needShowErrorMessage?: boolean
    needWholeErrorData?: boolean
    clearSession?: boolean
    isGCCheckoutRequest?: boolean
  }
  // Set interceptors before sending requests;
  ATGService.interceptors.request.use(
    (config: CustomAxiosRequestConfig) => {
      const { needLoading } = config

      if (needLoading) {
        store?.dispatch(save({ globalLoading: true }))
      }

      if (includes(config.url, 'getSessionConfirmationNumber')) {
        return config
      }

      const sessionNumber = sessionCache.fetched
        ? sessionCache.getSession()
        : ''

      if (!sessionNumber) {
        if (!sessionCache.isSessionFetching) {
          sessionCache.isSessionFetching = true
          getSessionNumber(true)
            .then(() => {
              sessionCache.fetched = true
              sessionCache.isSessionFetching = false
              while (sessionCache.pendingRequest.length) {
                sessionCache.pendingRequest.shift()?.()
              }
            })
            .catch(error => {
              logger.error({
                message: 'Error occurred when fetching session number ----->',
                err: error
              })
            })
        }

        return new Promise(resolve => {
          console.log(config.url, ' is pending.')
          sessionCache.pendingRequest.push(() => {
            resolve(config)
          })
        })
      }

      return config
    },
    (err: Error) => {
      store?.dispatch(save({ globalLoading: false }))
      return Promise.reject(err)
    }
  )

  // Set request acceptance interceptor
  ATGService.interceptors.response.use(
    async (res: AxiosResponse<IAxiosResponse>) => {
      const {
        config: {
          needShowErrorMessage = true,
          needWholeErrorData = false,
          clearSession,
          isGCCheckoutRequest = false
        } = {} as any
      } = res
      store?.dispatch(save({ globalLoading: false }))

      const { data } = res
      const { statusCode } = data
      if (statusCode === 'Failure' || statusCode === '01') {
        let errorMessage = ''

        forEach(data?.formExceptions, exception => {
          if (exception.localizedMessage) {
            errorMessage = exception.localizedMessage as string
            return false
          }
          return true
        })

        if (!errorMessage) {
          errorMessage = get(data, 'errorMsg')
        }
        if (needShowErrorMessage && isClient) {
          // Prefer user-friendly error messages
          const content = {
            content: createElement(
              'div',
              {
                dangerouslySetInnerHTML: {
                  __html:
                    friendlyErrorMessage[errorMessage] ||
                    errorMessage ||
                    'Error Happened!'
                },
                className: 'inline-block'
              },
              null
            )
          }

          await showErrorMessage(content, isGCCheckoutRequest)
        }
        if (needWholeErrorData) {
          // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
          return Promise.reject(data)
        }
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        return Promise.reject(errorMessage)
      }

      if (clearSession) {
        sessionCache.removeSession()
      }

      return res
    },
    err => {
      if (err?.response?.status === 409) {
        sessionCache.removeSession()
      }

      store?.dispatch(save({ globalLoading: false }))

      return Promise.reject(err as Error)
    }
  )
}

async function showErrorMessage(content: any, isGCCheckoutRequest: boolean) {
  try {
    const isCheckoutPath = window.location.pathname.startsWith('/checkout')

    if (isGCCheckoutRequest && isCheckoutPath) {
      const CheckoutAlertMessage = await import(
        /* webpackChunkName: "checkout-alert" */
        '@uc/global/CheckoutAlertMessage'
      )
      CheckoutAlertMessage?.CheckoutErrorMessage(content)
    } else {
      const message = await import(
        /* webpackChunkName: "ui-message" */
        '@gc/ui-components/message'
      )
      message?.ErrorMessage(content)
    }
  } catch (error) {
    console.error('Error showing error message:', error)
  }
}
