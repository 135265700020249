import { reduce, startsWith } from 'lodash'

export const getLastLocationPath = (): string => {
  const url = document.location.toString()
  const arrUrl = url.split('//')

  const start = arrUrl[1].indexOf('/')
  let relUrl = arrUrl[1].substring(start)

  relUrl = relUrl.split('?')?.reverse()[0]?.split('/')?.reverse()[0]

  return relUrl
}

export const readCookie = (name: string): string | null => {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';')
  for (let c of ca) {
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (startsWith(c, nameEQ))
      return unescape(c.substring(nameEQ.length, c.length))
  }
  return null
}

export const setCookie = ({
  name,
  value,
  expires,
  path,
  domain,
  secure
}: {
  name: string
  value: string
  expires?: { toGMTString: () => any }
  path?: string
  domain?: string
  secure?: string
}): void => {
  document.cookie = `${name}=${escape(value)}${
    expires ? `; expires=${expires.toGMTString()}` : ''
  }${path ? `; path=${path}` : '; path=/'}${
    domain ? `; domain=${domain}` : ''
  }${secure ? '; secure' : ''}`
}

export const clearCookie = cookieName => {
  const now = new Date()
  const expiration = new Date(now) as any
  expiration.setDate(now.getDate() - 1000)
  setCookie({ name: cookieName, value: '', expires: expiration })
}

export const getQueryValue = (queryName: string): string | null => {
  if (typeof window === 'undefined') return null
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(queryName)
}

export const isBrowser = (): boolean => {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  )
}

export function convertCookieToObj() {
  return reduce(
    document.cookie.split(';'),
    (result, cookie) => {
      const [key, value] = cookie.trim().split('=')
      result[key] = decodeURIComponent(value)
      return result
    },
    {}
  )
}
