import { createSlice } from '@reduxjs/toolkit'
import type { PLPTypings } from '@typings/plp'

export const initialState = {
  currentStyleDetails: {} as Partial<PLPTypings.IStyleOptionArr>,
  activeThumbIndex: -1,
  compareItemArr: [] as string[],
  currentSelectedSkuIdForFinancing: '',
  adverts: [] as PLPTypings.IAdvert[],
  seoFacetValues: [] as string[],
  showMobileRefinement: false
}
type actionType = Partial<typeof initialState>

export const userSlice = createSlice({
  name: 'algolia-plp',
  // state
  initialState,
  // sync action
  reducers: {
    save: (
      state,
      action: {
        payload: actionType
        type: string
      }
    ) => {
      Object.assign(state, action.payload)
    }
  }
})

export const { save } = userSlice.actions

export default userSlice.reducer
