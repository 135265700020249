import { PAYMENT_CREDIT } from '@gc/common-lib/constants/checkout/payment'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CheckoutTypings } from '@typings/checkout'

const initialState = {
  gearOrEssentialTerm: '',
  isMultiplePayment: false,
  selectedCreditCardIndex: -1,
  selectedGearCard: false,
  paymentGroupParams: {} as CheckoutTypings.IPaymentGroupItem | null,
  paymentType: PAYMENT_CREDIT,
  paymentEnable: true,
  giftFormFocus: false,
  isGiftCardFormOpen: false,
  roundupForCharitySelected: false,
  extraOneForCharitySelected: false,
  extraTwoForCharitySelected: false
}
type actionType = Partial<typeof initialState>

export const userSlice = createSlice({
  name: 'payment',
  // state
  initialState,
  // sync action
  reducers: {
    savePayment: (state, action: PayloadAction<actionType>) => {
      Object.assign(state, action.payload)
    },
    resetPaymentStore: () => initialState
  }
})

export const { savePayment, resetPaymentStore } = userSlice.actions

export default userSlice.reducer
