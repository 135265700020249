import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  currentSkuId: '',
  only_AK_available: false,
  only_HI_available: false,
  only_mainland_available: false,
  KCDC_available: true,
  isSkuNotAvailableForCurrentLocation: false,
  currentZipCode: '',
  isProductionInStock: false,
  availableStoreId: '',
  displayAddToCart: true
}

type actionType = Partial<typeof initialState>

export const userSlice = createSlice({
  name: 'storeComponent',
  // state
  initialState,
  // sync action
  reducers: {
    save: (state, action: PayloadAction<actionType>) => {
      Object.assign(state, action.payload)
    }
  }
})

export const { save } = userSlice.actions

export default userSlice.reducer
