import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CheckoutTypings } from '@typings/checkout'

const initialState = {
  checkoutInitData: {
    callCenterNumber: '866-498-7882',
    storeConfig: {
      audioEyeEnabled: true,
      showStoreDistance: true,
      rewardsCertificateLoginNecessary: false,
      checkoutSynchronyRewardsEnabled: false
    },
    messageContent: {}
  },
  shippingAddressForm: null as {
    email?: string
    fullName?: string
    phoneNumber?: string
    address1?: string
    address2?: string
    city?: string
    state?: string
    postalCode?: string
  } | null,
  pickUpAddressForm: null as {
    email?: string
    fullName?: string
    phoneNumber?: string
  } | null,
  storeForm: null as {
    zipAndCityState?: string
    radius?: number
  } | null,
  selectedStore: null as CheckoutTypings.IStore | null,
  gearCardNumber: null as string | null
}

type actionType = Partial<typeof initialState>

export const userSlice = createSlice({
  name: 'checkout',
  // state
  initialState,
  // sync action
  reducers: {
    save: (state, action: PayloadAction<actionType>) => {
      Object.assign(state, action.payload)
    }
  }
})

export const { save } = userSlice.actions

export default userSlice.reducer
