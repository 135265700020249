import type { IWishlist } from '@gc/common-lib/api/wishlist'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { Steps } from '@typings/global'
import { some } from 'lodash'

export interface IUndo {
  message: string
  autoCloseTime?: number
  undo?: () => void
  timestamp: number
}

export const defaultActionParams: {
  targetStep: Steps
  options: any
  isSessionTimeout?: boolean
} = {
  targetStep: Steps.SignIn,
  options: null,
  isSessionTimeout: false
}

export const initialState = {
  serverConfig: {} as any,
  showProCoverageBenefitsModal: false,
  restriction: '',
  drawerArray: [] as string[],
  globalLoading: false,
  failedLoginAttempts: 0,
  enableLDJSON: 'true',
  undoList: [] as IUndo[],
  userSignUpInShipping: false,
  actionParams: defaultActionParams,
  selectedWishlist: {} as IWishlist
}

type actionType = Partial<typeof initialState>

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    save: (state, action: PayloadAction<actionType>) => {
      Object.assign(state, action.payload)
    },
    changeDrawerArray: (state, action: PayloadAction<string>) => {
      const { payload } = action
      const { drawerArray } = state
      const hasStatus = some(drawerArray, item => item === payload)
      if (hasStatus) {
        drawerArray.pop()
      } else {
        drawerArray.push(payload)
      }
    },
    addUndo: (state, action: PayloadAction<IUndo>) => {
      state.undoList = [action.payload]
    },
    removeUndo: (state, action: PayloadAction<IUndo>) => {
      state.undoList = state.undoList.filter(
        undo => undo.timestamp !== action.payload.timestamp
      )
    }
  }
})

export const { save, changeDrawerArray, addUndo, removeUndo } =
  globalSlice.actions

export default globalSlice.reducer
