import axios from 'axios'
import https from 'https'
import { omit, set } from 'lodash'

import { logger } from './logger'

const agent = new https.Agent({
  rejectUnauthorized: process.env.SERVER_REQUEST_REJECT_UNAUTHORIZED === 'true'
})

export const devHeaders =
  process.env.NODE_ENV === 'development' && typeof window === 'undefined'
    ? {
        'User-Agent':
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/133.0.0.0 Safari/537.36'
      }
    : {}

export const serverRequest = axios.create({
  baseURL: process.env.NEXT_PUBLIC_AJAX_ORIGIN,
  httpsAgent: agent,
  headers: devHeaders,
  timeout: 60000
})

if (process.env.NEXT_PUBLIC_NEED_HEADER_HOST === 'true') {
  serverRequest.interceptors.request.use(config => {
    set(
      config,
      'headers.Host',
      process.env.NEXT_PUBLIC_HEADER_HOST || 'tl1-www.guitarcenter.com'
    )
    return config
  })
}

serverRequest.interceptors.response.use(
  res => res,
  error => {
    logger.error({
      message: '---- server side request failed ----',
      error: omit(error, 'config')
    })
    return Promise.reject(omit(error, 'config') as Error)
  }
)
