export const GC_BREAK_POINTS_CONFIG = {
  small: 320,
  // Configured only for the HeroTile component of the homepage
  mobileForHomePageHeroTiles: 667,
  middle: 768,
  legacy: 976,
  large: 1024,
  xl: 1280,
  '2xl': 1500
}
