export * from './event'
export * from './styled-jsx'

type childType = React.ReactElement | React.ReactElement[]

export interface ILayoutProps {
  children?: childType
}

export enum Steps {
  SignIn = 'SignIn',
  SignUp = 'SignUp',
  ForgotPassword = 'ForgotPassword',

  WishList = 'Wishlist',
  CreateWishlist = 'CreateWishlist'
}
